.section-img_bg_01 {
	height: 80%;
	display: block;
	background: url(../img/bg_001.jpg) no-repeat center center fixed;
	background-size: 100%;
	position: relative;
}
.img_bg_content {
	color: #fff;
	@include centerer(true, true);
}

#contact_form_thankyou {
  display: none;
}
.confirmemail {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.white-popup-block {
  background: #FFF;
  padding: 20px 30px;
  text-align: left;
  max-width: 650px;
  margin: 40px auto;
  position: relative;
}
.ajax_form .error {
    color: $red;
    border: 1px solid $red;
}
/**
 * Simple fade transition,
 */
.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out; 
  -moz-transition: all 0.15s ease-out; 
  transition: all 0.15s ease-out;
}
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out; 
  -moz-transition: all 0.15s ease-out; 
  transition: all 0.15s ease-out;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}
.jumbotron.jumbotron_small {
	height: 50%;
	background-position: center center;
}
.blog_post_item {
	img {
		max-width: 100%;
	}
}
.section-testimonials {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background: none !important;
}
.carousel-header, .carousel-inner, .carousel-item {
  padding-bottom: 3rem;
  background-color: $indigo;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 80% !important;
  height: 80vh !important;
  position: relative;
}
.section-testimonials .carousel-indicators {
  bottom: 70px !important;
}
.testimonials_item {
  .blockquote {
    @include centerer(true, true);
    color: $white;
    h3, .h3 {
      font-size: 1.2rem;
      @include media-breakpoint-up(md) {
        font-size: 1.75rem;
      }
    }
  }
}